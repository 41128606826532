/*
  * Rollup Common Services Library for JS applications
  * {@link https://github.com/united-finance/lib-js}
  * {@link https://unitedfinance.io/}
  * @copyright United Finance (@ivn703619)
  * @license MIT
  */
import Axios from'axios';var stableConfig = {
  webBaseUri: 'https://stable.unitedfinance.io/microservices/web',
  tonConnectManifestUri: 'https://stable.unitedfinance.io/statics/tonconnect-manifest.json',
  landingPageUri: 'https://stable.unitedfinance.io',
  dappPageUri: 'https://stable.unitedfinance.io/dapp',
  analyticsPageUri: 'https://stable.unitedfinance.io/analytics',
  docsPageUri: 'https://docs.unitedfinance.io/main',
  termsOfServicePageUri: 'https://docs.unitedfinance.io/main/other/terms-of-service',
  privacyPolicyPageUri: 'https://docs.unitedfinance.io/main/other/privacy-policy',
  telegramPageUri: 'https://t.me/united_finance',
  githubPageUri: 'https://github.com/united-finance'
};var prodConfig = {
  webBaseUri: 'https://unitedfinance.io/microservices/web',
  tonConnectManifestUri: 'https://unitedfinance.io/statics/tonconnect-manifest.json',
  landingPageUri: 'https://unitedfinance.io',
  dappPageUri: 'https://unitedfinance.io/dapp',
  analyticsPageUri: 'https://unitedfinance.io/analytics',
  docsPageUri: 'https://docs.unitedfinance.io/main',
  termsOfServicePageUri: 'https://docs.unitedfinance.io/main/other/terms-of-service',
  privacyPolicyPageUri: 'https://docs.unitedfinance.io/main/other/privacy-policy',
  telegramPageUri: 'https://t.me/united_finance',
  githubPageUri: 'https://github.com/united-finance'
};const configs = {
  stable: stableConfig,
  prod: prodConfig
};
let currentEnv = null;
let override = null;
let current = {};
function updateCurrentEnv() {
  current = Object.freeze({
    ...configs[currentEnv],
    ...override
  });
}
var config = {
  set env(value) {
    currentEnv = value;
    updateCurrentEnv();
  },
  set override(value) {
    override = {
      ...override,
      ...value
    };
    updateCurrentEnv();
  },
  get current() {
    return current;
  }
};const axiosExtra = {};
const methods = ['request', 'delete', 'get', 'head', 'options', 'post', 'put', 'patch'];
methods.forEach(method => {
  axiosExtra[`$${method}`] = function _() {
    return this[method](...arguments).then(res => res && res.data);
  };
});
function authInterceptor(auth) {
  return async config => {
    const authHeader = await auth.getAuthHeader();
    if (authHeader) {
      config.headers.common.Authorization = authHeader;
    }
    return config;
  };
}
function createAxios(_ref) {
  let {
    baseUri,
    auth
  } = _ref;
  const axios = Axios.create({
    baseURL: baseUri
  });
  Object.keys(axiosExtra).forEach(key => {
    axios[key] = axiosExtra[key].bind(axios);
  });
  if (auth) {
    axios.interceptors.request.use(authInterceptor(auth));
  }
  return axios;
}function factory(getConfig) {
  let instance = null;
  return () => {
    if (!instance) {
      instance = createAxios(getConfig());
    }
    return instance;
  };
}const axios = factory(() => ({
  baseUri: config.current.webBaseUri
}));
var web = {
  async getWalletAddressUUsd(walletAddress) {
    return axios().$get(`api/v1/wallet/uusd/address?walletAddress=${walletAddress}`);
  },
  async getWalletAddressUsdt(walletAddress) {
    return axios().$get(`api/v1/wallet/usdt/address?walletAddress=${walletAddress}`);
  },
  async getWalletDataUUsd(walletAddress) {
    return axios().$get(`api/v1/wallet/uusd/data?walletV4Address=${walletAddress}`);
  },
  async getWalletDataUsdt(walletAddress) {
    return axios().$get(`api/v1/wallet/usdt/data?walletV4Address=${walletAddress}`);
  },
  async getJettonPool(walletAddress) {
    return axios().$get(`api/v1/wallet/jetton-pool?walletV4Address=${walletAddress}`);
  },
  async getSharesByUUsd(balance) {
    return axios().$get(`api/v1/jettons/uusd/shares?balance=${balance}`);
  },
  async getTvlUUsd() {
    return axios().$get('api/v1/statistics/uusd/tvl');
  },
  async getTvlUsdt() {
    return axios().$get('api/v1/statistics/usdt/tvl');
  },
  async getApyUUsd(_ref) {
    let {
      intervalInDays
    } = _ref;
    return axios().$get(`api/v1/statistics/uusd/apy?intervalInDays=${intervalInDays}`);
  }
};var constants = {
  fieldError: 'field-error'
};function isString(x) {
  return typeof x === 'string' || x instanceof String;
}
function isNumber(x) {
  return typeof x === 'number' && Number.isFinite(x);
}
function isFunction(x) {
  return typeof x === 'function';
}
function isArray(x) {
  return x && typeof x === 'object' && x.constructor === Array;
}
var types = {
  isString,
  isNumber,
  isFunction,
  isArray
};function number$1(str) {
  if (!types.isString(str)) {
    return NaN;
  }
  const filtered = str.replace(/[^0-9,.+-]/g, '').replace(',', '.');
  return parseFloat(filtered);
}
var parse = {
  number: number$1
};function currency() {
  return '';
}
function getInternationalIntPartGroups(intPart) {
  let begin = 0;
  let end = (intPart.length - 1) % 3 + 1;
  const groups = [];
  while (begin < intPart.length) {
    groups.push(intPart.substring(begin, end));
    begin = end;
    end += 3;
  }
  return groups;
}
function pad(val, len) {
  const str = String(val);
  if (str.length >= len) {
    return str;
  }
  return `${'0'.repeat(len - str.length)}${str}`;
}
function number(val) {
  let {
    fractionDigits,
    hideZeroFraction,
    forceSign,
    useSoftSpace,
    nullValue,
    gSep,
    dSep
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (val === null) {
    return nullValue;
  }
  if (!types.isNumber(val)) {
    return null;
  }
  if (val === 0) {
    return !fractionDigits || hideZeroFraction ? '0' : `0,${'0'.repeat(fractionDigits)}`;
  }
  const sp = gSep || (useSoftSpace ? ' ' : '\xa0');
  const sign = val < 0 ? '-' : forceSign ? '+' : '';
  const absVal = val < 0 ? -val : val;
  const intPart = Math.floor(absVal).toString();
  const groups = getInternationalIntPartGroups(intPart);
  const intPartStr = `${sign}${groups.join(sp)}`;
  if (!fractionDigits) {
    return intPartStr;
  }
  const fracMultiplier = 10 ** fractionDigits;
  const multResult = parseInt((val * fracMultiplier).toFixed(intPart.length + fractionDigits), 10);
  const fracPart = Math.floor(multResult) % fracMultiplier;
  if (fracPart === 0 && hideZeroFraction) {
    return intPartStr;
  }
  const dsp = dSep || ',';
  const fracPartStr = pad(fracPart.toString(), fractionDigits);
  return `${intPartStr}${dsp}${fracPartStr}`;
}
function money(value, hideZeroFraction, hideSymbol) {
  const result = number(value, {
    fractionDigits: 9,
    hideZeroFraction
  });
  if (!result) {
    return null;
  }
  return hideSymbol ? result : `${result}\xa0${currency()}`;
}
var format = {
  pad,
  number,
  money
};const noise = canvasId => {
  let canvas;
  let ctx;
  let wWidth;
  let wHeight;
  const noiseData = [];
  let frame = 0;

  // Create Noise
  const createNoise = () => {
    const idata = ctx.createImageData(wWidth, wHeight);
    const buffer32 = new Uint32Array(idata.data.buffer);
    const len = buffer32.length;
    for (let i = 0; i < len; i++) {
      if (Math.random() < 0.5) {
        buffer32[i] = 0xff000000;
      }
    }
    noiseData.push(idata);
  };

  // Play Noise
  const paintNoise = () => {
    if (frame === 9) {
      frame = 0;
    } else {
      frame++;
    }
    ctx.putImageData(noiseData[frame], 0, 0);
  };

  // Loop
  const loop = () => {
    paintNoise();
    window.setTimeout(() => {
      window.requestAnimationFrame(loop);
    }, 1000 / 25);
  };

  // Setup
  const setup = () => {
    wWidth = window.innerWidth;
    wHeight = window.innerHeight;
    canvas.width = wWidth;
    canvas.height = wHeight;
    for (let i = 0; i < 10; i++) {
      createNoise();
    }
    loop();
  };

  // Init
  (() => {
    canvas = document.getElementById(canvasId);
    ctx = canvas.getContext('2d');
    setup();
  })();
};
function noise$1 () {
  return {
    noise
  };
}const resultCode = Object.freeze({
  succeeded: 1,
  notFound: 101
});
var core = {
  resultCode
};const jettons = Object.freeze({
  uusd: 'uusd',
  wuusd: 'wuusd',
  usdt: 'usdt'
});
const jettonIcons = Object.freeze({
  uusd: 'uusd',
  wuusd: 'wuusd',
  usdt: 'usdt'
});
const jettonGroups = Object.freeze({
  common: 'common',
  unitedFinance: 'united-finance'
});
var jetton = {
  jettons,
  jettonIcons,
  jettonGroups
};const layoutColors = Object.freeze({
  primary: 'primary',
  secondary: 'secondary',
  third: 'third'
});
var style = {
  layoutColors
};export{config,constants,core as coreEnums,format,jetton as jettonEnums,noise$1 as noiseService,parse,style as styleEnums,types,web as webClient};