/*
  * Rollup Services Library for a landing
  * {@link https://github.com/united-finance/lib-js}
  * {@link https://unitedfinance.io/}
  * @copyright United Finance (@ivn703619)
  * @license MIT
  */
import {webClient,coreEnums,jettonEnums,types}from'@uf-libjs/services';import TonWeb from'tonweb';import BN from'bn.js';import {TonConnectUI,THEME}from'@tonconnect/ui';function wallet () {
  return {
    async getWalletAddressUUsd(walletAddress) {
      try {
        const address = await webClient.getWalletAddressUUsd(walletAddress);
        return {
          succeeded: true,
          address
        };
      } catch (error) {
        return {
          succeeded: false,
          address: null
        };
      }
    },
    async getWalletAddressUsdt(walletAddress) {
      try {
        const address = await webClient.getWalletAddressUsdt(walletAddress);
        return {
          succeeded: true,
          address
        };
      } catch (error) {
        return {
          succeeded: false,
          address: null
        };
      }
    },
    async getWalletDataUUsd(walletAddress) {
      try {
        const {
          code,
          data
        } = await webClient.getWalletDataUUsd(walletAddress);
        const {
          succeeded
        } = coreEnums.resultCode;
        if (code !== succeeded) {
          return {
            succeeded: false,
            data: null
          };
        }
        return {
          succeeded: true,
          data
        };
      } catch (error) {
        return {
          succeeded: false,
          data: null
        };
      }
    },
    async getWalletDataUsdt(walletAddress) {
      try {
        const {
          code,
          data
        } = await webClient.getWalletDataUsdt(walletAddress);
        const {
          succeeded
        } = coreEnums.resultCode;
        if (code !== succeeded) {
          return {
            succeeded: false,
            data: null
          };
        }
        return {
          succeeded: true,
          data
        };
      } catch (error) {
        return {
          succeeded: false,
          data: null
        };
      }
    },
    async getJettonPool(walletAddress) {
      try {
        const {
          code,
          data
        } = await webClient.getJettonPool(walletAddress);
        const {
          succeeded
        } = coreEnums.resultCode;
        if (code !== succeeded) {
          return {
            succeeded: false,
            items: null
          };
        }
        return {
          succeeded: true,
          jettonPoolItems: data
        };
      } catch (error) {
        return {
          succeeded: false,
          jettonPoolItems: []
        };
      }
    }
  };
}function statistics () {
  return {
    async getTvlUUsd() {
      try {
        const tvl = await webClient.getTvlUUsd();
        return {
          succeeded: true,
          tvl
        };
      } catch (error) {
        return {
          succeeded: false,
          tvl: null
        };
      }
    },
    async getTvlUsdt() {
      try {
        const tvl = await webClient.getTvlUsdt();
        return {
          succeeded: true,
          tvl
        };
      } catch (error) {
        return {
          succeeded: false,
          tvl: null
        };
      }
    },
    async getApyUUsd(_ref) {
      let {
        intervalInDays
      } = _ref;
      try {
        const apy = await webClient.getApyUUsd({
          intervalInDays
        });
        return {
          succeeded: true,
          apy
        };
      } catch (error) {
        return {
          succeeded: false,
          apy: null
        };
      }
    }
  };
}function getJettonsData() {
  const {
    jettonIcons,
    jettons,
    jettonGroups
  } = jettonEnums;
  return [{
    caption: 'uUSD',
    icon: jettonIcons.uusd,
    name: jettons.uusd,
    group: jettonGroups.unitedFinance,
    balance: 0
  }, {
    caption: 'wuUSD',
    icon: jettonIcons.wuusd,
    name: jettons.wuusd,
    group: jettonGroups.unitedFinance,
    balance: 0
  }, {
    caption: 'USDT',
    icon: jettonIcons.usdt,
    name: jettons.usdt,
    group: jettonGroups.common,
    balance: 0
  }];
}
function getJettonDataByName(name, jettonsData) {
  const [data] = (jettonsData || getJettonsData()).filter(jetton => jetton.name === name);
  return data || null;
}
function getJettonConvertionPairs() {
  const {
    jettons
  } = jettonEnums;
  return [{
    source: jettons.usdt,
    target: [jettons.wuusd]
  }, {
    source: jettons.wuusd,
    target: [jettons.usdt]
  }
  // {
  //   source: jettons.usdt,
  //   target: [jettons.uusd, jettons.wuusd],
  // },
  // {
  //   source: jettons.uusd,
  //   target: [jettons.usdt, jettons.wuusd],
  // },
  // {
  //   source: jettons.wuusd,
  //   target: [jettons.usdt, jettons.uusd],
  // },
  ];
}

function getJettonObjectConvertionPairs(jettonsData) {
  return getJettonConvertionPairs().map(_ref => {
    let {
      source,
      target
    } = _ref;
    return {
      source: getJettonDataByName(source, jettonsData),
      target: target.map(t => getJettonDataByName(t, jettonsData)).filter(t => t !== null) || []
    };
  }).filter(_ref2 => {
    let {
      source,
      target
    } = _ref2;
    return source !== null && target.length > 0;
  }) || [];
}
async function getSharesByUUsd(balance) {
  try {
    const {
      code,
      data
    } = await webClient.getSharesByUUsd(balance);
    const {
      succeeded
    } = coreEnums.resultCode;
    if (code !== succeeded) {
      return {
        succeeded: false,
        shares: null
      };
    }
    return {
      succeeded: true,
      shares: data
    };
  } catch (error) {
    return {
      succeeded: false,
      shares: null
    };
  }
}
async function convertJettonsToShares(_ref3) {
  let {
    balanceInJettons,
    decimals
  } = _ref3;
  const balance = balanceInJettons * 10 ** decimals;
  const {
    succeeded,
    shares
  } = await getSharesByUUsd(balance);
  if (!succeeded) {
    return {
      balanceInShares: 0,
      succeeded: false
    };
  }
  const balanceInShares = shares / 10 ** decimals;
  return {
    balanceInShares,
    succeeded: true
  };
}
async function convertBalance(_ref4) {
  let {
    balanceInJettons,
    decimals,
    jettonName
  } = _ref4;
  const {
    jettons
  } = jettonEnums;
  if (jettonName === jettons.uusd) {
    const {
      succeeded,
      balanceInShares
    } = await convertJettonsToShares({
      balanceInJettons,
      decimals
    });
    return {
      succeeded,
      balance: balanceInShares
    };
  }
  return {
    succeeded: true,
    balance: balanceInJettons
  };
}
function jettons () {
  return {
    getJettonsData,
    getJettonObjectConvertionPairs,
    getSharesByUUsd,
    convertBalance
  };
}function getBalanceByJettonName(name, _ref) {
  let {
    jettonsPoolData
  } = _ref;
  const [sourceJetton] = jettonsPoolData.filter(jetton => jetton.from === name);
  if (sourceJetton) {
    return sourceJetton.balanceFrom / 10 ** sourceJetton.decimalsFrom;
  }
  const [targetJetton] = jettonsPoolData.filter(jetton => jetton.to === name);
  if (targetJetton) {
    return targetJetton.balanceTo / 10 ** targetJetton.decimalsTo;
  }
  return 0;
}
function enrichJettonsData(jettonsData, jettonsPoolData) {
  if (!types.isArray(jettonsData)) {
    return [];
  }
  if (!types.isArray(jettonsPoolData)) {
    return jettonsData;
  }
  return jettonsData.map(jetton => ({
    ...jetton,
    balance: getBalanceByJettonName(jetton.name, {
      jettonsPoolData
    })
  }));
}
function jettonsPool () {
  return {
    enrichJettonsData
  };
}function create() {
  function toNanoString(amount) {
    return TonWeb.utils.toNano(amount).toString();
  }
  function prepareParameters(_ref, _ref2) {
    let {
      jettonNameFrom,
      jettonNameTo
    } = _ref;
    let {
      balance,
      jettonWalletAddress,
      vaultAddress,
      walletV4Address
    } = _ref2;
    const {
      jettons
    } = jettonEnums;
    const amount = balance.toString();
    const items = [
    // transfer USDT to uUSD
    {
      jettonNameFrom: jettons.usdt,
      jettonNameTo: jettons.uusd,
      parameters: {
        walletUsdtAddress: jettonWalletAddress,
        walletV4Address,
        vaultAddress,
        amount
      }
    },
    // burn uUSD to USDT
    {
      jettonNameFrom: jettons.uusd,
      jettonNameTo: jettons.usdt,
      parameters: {
        walletUUsdAddress: jettonWalletAddress,
        walletV4Address,
        amount
      }
    },
    // transfer USDT to wuUSD
    {
      jettonNameFrom: jettons.usdt,
      jettonNameTo: jettons.wuusd,
      parameters: {
        walletUsdtAddress: jettonWalletAddress,
        walletV4Address,
        vaultAddress,
        amount
      }
    },
    // burn wuUSD to USDT
    {
      jettonNameFrom: jettons.wuusd,
      jettonNameTo: jettons.usdt,
      parameters: {
        walletWUUsdAddress: jettonWalletAddress,
        walletV4Address,
        amount
      }
    },
    // transfer uUSD to wuUSD
    {
      jettonNameFrom: jettons.uusd,
      jettonNameTo: jettons.wuusd,
      parameters: {
        walletUUsdAddress: jettonWalletAddress,
        walletV4Address,
        vaultAddress,
        amount
      }
    },
    // burn wuUSD to uUSD
    {
      jettonNameFrom: jettons.wuusd,
      jettonNameTo: jettons.uusd,
      parameters: {
        walletWUUsdAddress: jettonWalletAddress,
        walletV4Address,
        amount
      }
    }];
    const [item] = items.filter(x => x.jettonNameFrom === jettonNameFrom && x.jettonNameTo === jettonNameTo);
    return item && item.parameters || null;
  }

  /* ---------------------------------------------- */
  /* Body for a message for a transactions          */
  /* ---------------------------------------------- */

  function writeShareCoins(bits, amount) {
    if (amount === 0) {
      bits.writeUint(0, 5);
    } else {
      amount = new BN(amount);
      const l = Math.ceil(amount.toString(2).length / 8);
      bits.writeUint(l, 5);
      bits.writeUint(amount, l * 8);
    }
    return bits;
  }
  async function prepareBodyForJettonTransferUsdtToUUsd(_ref3) {
    let {
      walletSourceAddress,
      walletDestinationAddress,
      amount,
      forwardTonAmount
    } = _ref3;
    // https://docs.ton.org/develop/dapps/ton-connect/message-builders#jetton-transfer

    // transfer#0f8a7ea5
    // query_id:uint64
    // amount:(VarUInteger 16) in jettons
    // destination:MsgAddress
    // response_destination:MsgAddress
    // custom_payload:(Maybe ^Cell)
    // forward_ton_amount:(VarUInteger 16) in nanotons
    // forward_payload:(Either Cell ^Cell)
    // = InternalMsgBody;

    const cell = new TonWeb.boc.Cell();
    cell.bits.writeUint(0x0f8a7ea5, 32); // jetton transfer op code
    cell.bits.writeUint(new Date().getTime(), 64); // query_id:uint64
    cell.bits.writeCoins(amount); // amount:(VarUInteger 16) -  Jetton amount for transfer (decimals = 6 - jUSDT, 9 - default(we use it))
    cell.bits.writeAddress(new TonWeb.Address(walletDestinationAddress)); // destination:MsgAddress (for a Vault)
    cell.bits.writeAddress(new TonWeb.Address(walletSourceAddress)); // response_destination:MsgAddress
    cell.bits.writeUint(0, 1); // custom_payload:(Maybe ^Cell)
    cell.bits.writeCoins(forwardTonAmount); // forward_ton_amount:(VarUInteger 16)
    cell.bits.writeUint(0, 1); // forward_payload:(Either Cell ^Cell)

    const bocBytes = await cell.toBoc();
    const bocBase64 = TonWeb.utils.bytesToBase64(bocBytes);
    return bocBase64;
  }
  async function prepareBodyForRebaseJettonBurnUUsdToUsdt(_ref4) {
    let {
      walletSourceAddress,
      amount
    } = _ref4;
    // https://docs.ton.org/develop/dapps/ton-connect/message-builders#jetton-burn
    // https://github.com/united-finance/TEPs/blob/master/text/0000-rebase-jettons-standard.md#2-burn

    // burn#dbc5a2f9
    // query_id:uint64
    // amount:(VarUInteger 16) in shares
    // response_destination:MsgAddress
    // custom_payload:(Maybe ^Cell)
    // = InternalMsgBody;

    const cell = new TonWeb.boc.Cell();
    cell.bits.writeUint(0xdbc5a2f9, 32); // rebase jetton burn op code
    cell.bits.writeUint(new Date().getTime(), 64); // query_id:uint64
    cell.bits = writeShareCoins(cell.bits, amount); // amount:(VarUInteger 16) -  Share amount in decimal  (decimals = 9)
    cell.bits.writeAddress(new TonWeb.Address(walletSourceAddress)); // response_destination:MsgAddress - owner's wallet
    cell.bits.writeUint(0, 1); // custom_payload:(Maybe ^Cell) - w/o payload typically

    const bocBytes = await cell.toBoc();
    const bocBase64 = TonWeb.utils.bytesToBase64(bocBytes);
    return bocBase64;
  }
  async function prepareBodyForJettonTransferUsdtToWUUsd(_ref5) {
    let {
      walletSourceAddress,
      walletDestinationAddress,
      amount,
      forwardTonAmount
    } = _ref5;
    // transfer#0f8a7ea5
    // query_id:uint64
    // amount:(VarUInteger 16) in jettons
    // destination:MsgAddress
    // response_destination:MsgAddress
    // custom_payload:(Maybe ^Cell)
    // forward_ton_amount:(VarUInteger 16) in nanotons
    // is_exists_payload_transfer_from_UUsd_to_WUUsd:(Either Cell ^Cell)
    // payload_transfer_from_UUsd_to_WUUsd:(Either Cell ^Cell)
    // eitherFwd_payload: Cell
    // = InternalMsgBody;

    const mintUsdtVaultToWUUsdMinterTonAmount = toNanoString('0.02');
    const transferWUUsdMinterToWUUsdWalletTonAmount = toNanoString('0.01');
    const opCodeMintWUUsd = 0xa9263ba3;
    const payloadTransferUUsdToWUUsdCell = new TonWeb.boc.Cell();
    payloadTransferUUsdToWUUsdCell.bits.writeUint(opCodeMintWUUsd, 32);
    payloadTransferUUsdToWUUsdCell.writeCoins(mintUsdtVaultToWUUsdMinterTonAmount);
    payloadTransferUUsdToWUUsdCell.writeCoins(transferWUUsdMinterToWUUsdWalletTonAmount);
    const cell = new TonWeb.boc.Cell();
    cell.bits.writeUint(0x0f8a7ea5, 32);
    cell.bits.writeUint(new Date().getTime(), 64);
    cell.bits.writeCoins(amount);
    cell.bits.writeAddress(new TonWeb.Address(walletDestinationAddress));
    cell.bits.writeAddress(new TonWeb.Address(walletSourceAddress));
    cell.bits.writeUint(0, 1);
    cell.bits.writeCoins(forwardTonAmount);
    cell.bits.writeUint(1, 1);
    cell.writeCell(payloadTransferUUsdToWUUsdCell);
    const bocBytes = await cell.toBoc();
    const bocBase64 = TonWeb.utils.bytesToBase64(bocBytes);
    return bocBase64;
  }
  async function prepareBodyForJettonBurnWUUsdToUsdt(_ref6) {
    let {
      walletSourceAddress,
      amount
    } = _ref6;
    // burn#dbc5a2f9
    // query_id:uint64
    // amount:(VarUInteger 16) in jettons
    // response_destination:MsgAddress
    // custom_payload:(Maybe ^Cell)
    // is_exists_payload_transfer_from_UUsd_to_Usdt:(Either Cell ^Cell)
    // payload_transfer_from_UUsd_to_Usdt:(Either Cell ^Cell)
    // = InternalMsgBody;

    const cell = new TonWeb.boc.Cell();
    cell.bits.writeUint(0x595f07bc, 32);
    cell.bits.writeUint(new Date().getTime(), 64);
    cell.bits.writeCoins(amount);
    cell.bits.writeAddress(new TonWeb.Address(walletSourceAddress));
    cell.bits.writeUint(0, 1);
    cell.bits.writeUint(0, 1);
    const bocBytes = await cell.toBoc();
    const bocBase64 = TonWeb.utils.bytesToBase64(bocBytes);
    return bocBase64;
  }
  async function prepareBodyForRebaseJettonTransferUUsdToWUUsd(_ref7) {
    let {
      walletSourceAddress,
      walletDestinationAddress,
      amount,
      forwardTonAmount
    } = _ref7;
    // transfer#0f8a7ea5
    // query_id:uint64
    // amount:(VarUInteger 16) in shares
    // destination:MsgAddress
    // response_destination:MsgAddress
    // custom_payload:(Maybe ^Cell) in nanotons
    // forward_ton_amount:(VarUInteger 16)
    // forward_payload:(Either Cell ^Cell)
    // = InternalMsgBody;

    const cell = new TonWeb.boc.Cell();
    cell.bits.writeUint(0xdc9ae889, 32);
    cell.bits.writeUint(new Date().getTime(), 64);
    cell.bits = writeShareCoins(cell.bits, amount);
    cell.bits.writeAddress(new TonWeb.Address(walletDestinationAddress));
    cell.bits.writeAddress(new TonWeb.Address(walletSourceAddress));
    cell.bits.writeUint(0, 1);
    cell.bits.writeCoins(forwardTonAmount);
    cell.bits.writeUint(0, 1);
    const bocBytes = await cell.toBoc();
    const bocBase64 = TonWeb.utils.bytesToBase64(bocBytes);
    return bocBase64;
  }
  async function prepareBodyForJettonBurnWUUsdToUUsd(_ref8) {
    let {
      walletSourceAddress,
      amount
    } = _ref8;
    // burn#dbc5a2f9
    // query_id:uint64
    // amount:(VarUInteger 16) in jettons
    // response_destination:MsgAddress
    // custom_payload:(Maybe ^Cell)
    // = InternalMsgBody;

    const cell = new TonWeb.boc.Cell();
    cell.bits.writeUint(0x595f07bc, 32);
    cell.bits.writeUint(new Date().getTime(), 64);
    cell.bits.writeCoins(amount);
    cell.bits.writeAddress(new TonWeb.Address(walletSourceAddress));
    cell.bits.writeUint(0, 1);
    const bocBytes = await cell.toBoc();
    const bocBase64 = TonWeb.utils.bytesToBase64(bocBytes);
    return bocBase64;
  }

  /* ---------------------------------------------- */
  /* Messages for transactions for jettons          */
  /* ---------------------------------------------- */

  function createTransactionMessage(_ref9) {
    let {
      jettonWalletAddress,
      gasPaymentAmount,
      body = null
    } = _ref9;
    const address = jettonWalletAddress; // Jetton Wallet contract address, that defined based on JettonMaser and Wallet contracts
    const amount = gasPaymentAmount; // Integer, amount of Toncoin for gas payments in nanotons. For commission fees, excess will be returned.
    const payload = body || null; // payload with jetton transfer body

    return payload ? {
      address,
      amount,
      payload
    } : {
      address,
      amount
    };
  }
  async function createUsdtToUUsdTransactionMessage(_ref10) {
    let {
      walletUsdtAddress,
      walletV4Address,
      vaultAddress,
      amount
    } = _ref10;
    const gasPaymentAmount = toNanoString('0.08');
    const forwardTonAmount = toNanoString('0.025');
    const body = await prepareBodyForJettonTransferUsdtToUUsd({
      walletSourceAddress: walletV4Address,
      walletDestinationAddress: vaultAddress,
      amount: toNanoString(amount),
      forwardTonAmount
    });
    const message = createTransactionMessage({
      jettonWalletAddress: walletUsdtAddress,
      gasPaymentAmount,
      body
    });
    return message;
  }
  async function createUUsdToUsdtTransactionMessage(_ref11) {
    let {
      walletUUsdAddress,
      walletV4Address,
      amount
    } = _ref11;
    const gasPaymentAmount = toNanoString('0.08');
    const body = await prepareBodyForRebaseJettonBurnUUsdToUsdt({
      walletSourceAddress: walletV4Address,
      amount: toNanoString(amount)
    });
    const message = createTransactionMessage({
      jettonWalletAddress: walletUUsdAddress,
      gasPaymentAmount,
      body
    });
    return message;
  }
  async function createUsdtToWUUsdTransactionMessage(_ref12) {
    let {
      walletUsdtAddress,
      walletV4Address,
      vaultAddress,
      amount
    } = _ref12;
    const gasPaymentAmount = toNanoString('0.08');
    const forwardTonAmount = toNanoString('0.025');
    const body = await prepareBodyForJettonTransferUsdtToWUUsd({
      walletSourceAddress: walletV4Address,
      walletDestinationAddress: vaultAddress,
      amount: toNanoString(amount),
      forwardTonAmount
    });
    const message = createTransactionMessage({
      jettonWalletAddress: walletUsdtAddress,
      gasPaymentAmount,
      body
    });
    return message;
  }
  async function createWUUsdToUsdtTransactionMessage(_ref13) {
    let {
      walletWUUsdAddress,
      walletV4Address,
      amount
    } = _ref13;
    const gasPaymentAmount = toNanoString('0.08');
    const body = await prepareBodyForJettonBurnWUUsdToUsdt({
      walletSourceAddress: walletV4Address,
      amount: toNanoString(amount)
    });
    const message = createTransactionMessage({
      jettonWalletAddress: walletWUUsdAddress,
      gasPaymentAmount,
      body
    });
    return message;
  }
  async function createUUsdToWUUsdTransactionMessage(_ref14) {
    let {
      walletUUsdAddress,
      walletV4Address,
      vaultAddress,
      amount
    } = _ref14;
    const gasPaymentAmount = toNanoString('0.08');
    const forwardTonAmount = toNanoString('0.025');
    const body = await prepareBodyForRebaseJettonTransferUUsdToWUUsd({
      walletSourceAddress: walletV4Address,
      walletDestinationAddress: vaultAddress,
      amount: toNanoString(amount),
      forwardTonAmount
    });
    const message = createTransactionMessage({
      jettonWalletAddress: walletUUsdAddress,
      gasPaymentAmount,
      body
    });
    return message;
  }
  async function createWUUsdToUUsdTransactionMessage(_ref15) {
    let {
      walletWUUsdAddress,
      walletV4Address,
      amount
    } = _ref15;
    const gasPaymentAmount = toNanoString('0.08');
    const body = await prepareBodyForJettonBurnWUUsdToUUsd({
      walletSourceAddress: walletV4Address,
      amount: toNanoString(amount)
    });
    const message = createTransactionMessage({
      jettonWalletAddress: walletWUUsdAddress,
      gasPaymentAmount,
      body
    });
    return message;
  }
  function getMessageGenerationHandler(_ref16) {
    let {
      jettonNameFrom,
      jettonNameTo
    } = _ref16;
    const {
      jettons
    } = jettonEnums;
    const items = [
    // transfer USDT to uUSD
    {
      jettonNameFrom: jettons.usdt,
      jettonNameTo: jettons.uusd,
      handler: createUsdtToUUsdTransactionMessage
    },
    // burn uUSD to USDT
    {
      jettonNameFrom: jettons.uusd,
      jettonNameTo: jettons.usdt,
      handler: createUUsdToUsdtTransactionMessage
    },
    // transfer USDT to wuUSD
    {
      jettonNameFrom: jettons.usdt,
      jettonNameTo: jettons.wuusd,
      handler: createUsdtToWUUsdTransactionMessage
    },
    // burn wuUSD to USDT
    {
      jettonNameFrom: jettons.wuusd,
      jettonNameTo: jettons.usdt,
      handler: createWUUsdToUsdtTransactionMessage
    },
    // transfer uUSD to wuUSD
    {
      jettonNameFrom: jettons.uusd,
      jettonNameTo: jettons.wuusd,
      handler: createUUsdToWUUsdTransactionMessage
    },
    // burn wuUSD to uUSD
    {
      jettonNameFrom: jettons.wuusd,
      jettonNameTo: jettons.uusd,
      handler: createWUUsdToUUsdTransactionMessage
    }];
    const [item] = items.filter(x => x.jettonNameFrom === jettonNameFrom && x.jettonNameTo === jettonNameTo);
    return item && item.handler || null;
  }

  /* ---------------------------------------------- */
  /* TON Connect UI                                          */
  /* ---------------------------------------------- */

  function getDefaultTonConnectUiCreateOptions(_ref17) {
    let {
      connectButtonId,
      manifestUrl
    } = _ref17;
    return {
      manifestUrl,
      buttonRootId: connectButtonId,
      actionsConfiguration: {
        modals: ['before', 'success', 'error'],
        notifications: ['before', 'success', 'error']
      },
      uiPreferences: {
        theme: THEME.DARK
      },
      restoreConnection: true
    };
  }

  /* ---------------------------------------------- */
  /* state                                          */
  /* ---------------------------------------------- */

  function createState() {
    return {
      tonConnectUILoading: false,
      tonConnectUI: null,
      connected: false,
      unsubscribeHandler: null
    };
  }

  /* ---------------------------------------------- */
  /* getters                                        */
  /* ---------------------------------------------- */

  function isWalletConnected(state) {
    return state && state.connected || false;
  }
  function walletAddress(state) {
    return state && state.tonConnectUI && state.tonConnectUI.connector && state.tonConnectUI.connector._wallet && state.tonConnectUI.connector._wallet.account && state.tonConnectUI.connector._wallet.account.address || null;
  }
  function isTestWalletAddress(_, getters) {
    if (!getters.walletAddress) {
      return false;
    }
    const address = new TonWeb.utils.Address(getters.walletAddress);
    const {
      isTestOnly
    } = address;
    return !!isTestOnly;
  }
  function isTonConnectUILoading(state) {
    return state && state.tonConnectUILoading || false;
  }

  /* ---------------------------------------------- */
  /* mutations                                      */
  /* ---------------------------------------------- */
  /* eslint-disable no-param-reassign */

  function updateTonConnectUILoading(state, _ref18) {
    let {
      loading
    } = _ref18;
    state.tonConnectUILoading = loading;
  }
  function updateTonConnectUI(state, _ref19) {
    let {
      tonConnectUI
    } = _ref19;
    state.tonConnectUI = tonConnectUI;
  }
  function setIsWalletConnected(state, _ref20) {
    let {
      connected
    } = _ref20;
    state.connected = connected;
  }
  function setUnsubscribeHandler(state, _ref21) {
    let {
      handler
    } = _ref21;
    state.unsubscribeHandler = handler;
  }

  /* eslint-enable no-param-reassign */
  /* ---------------------------------------------- */
  /* actions                                        */
  /* ---------------------------------------------- */

  function dispose(_ref22) {
    let {
      state
    } = _ref22;
    if (types.isFunction(state.unsubscribeHandler)) {
      state.unsubscribeHandler();
    }
  }
  async function initialize(_ref23) {
    let {
      state,
      commit
    } = _ref23;
    let {
      connectButtonId,
      manifestUrl
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    commit('updateTonConnectUILoading', {
      loading: true
    });
    const options = getDefaultTonConnectUiCreateOptions({
      connectButtonId,
      manifestUrl
    });
    const tonConnectUI = new TonConnectUI(options);
    const unsubscribe = tonConnectUI.onStatusChange(wallet => {
      commit('setIsWalletConnected', {
        connected: !!wallet
      });
      commit('updateTonConnectUILoading', {
        loading: false
      });
    });
    commit('updateTonConnectUI', {
      tonConnectUI
    });
    commit('setUnsubscribeHandler', {
      handler: unsubscribe
    });
  }
  async function openSelectWalletModal(_ref24) {
    let {
      state,
      getters
    } = _ref24;
    if (!getters.isWalletConnected) {
      const {
        modal
      } = state.tonConnectUI;
      await modal.open();
    }
  }
  async function disconnectWallet(_ref25) {
    let {
      state
    } = _ref25;
    await state.tonConnectUI.disconnect();
  }
  async function sendTransaction(_ref26, _ref27) {
    let {
      state,
      getters
    } = _ref26;
    let {
      messages
    } = _ref27;
    if (!getters.isWalletConnected) {
      return {
        succeeded: false,
        disconnected: true,
        result: null
      };
    }
    if (!types.isArray(messages)) {
      return {
        succeeded: false,
        result: null
      };
    }
    try {
      const timeThreshold = Math.floor(Date.now() / 1000) + 360; // 360 sec

      const result = await state.tonConnectUI.sendTransaction({
        validUntil: timeThreshold,
        messages
      });
      if (!result) {
        return {
          succeeded: false,
          result: null
        };
      }
      return {
        succeeded: true,
        result
      };
    } catch (error) {
      console.log(error);
      return {
        succeeded: false,
        result: null
      };
    }
  }
  async function prepareTransactionMessage(_ref28, _ref29) {
    let {
      jettons: {
        jettonNameFrom,
        jettonNameTo
      },
      data: {
        balance,
        jettonWalletAddress,
        vaultAddress,
        walletV4Address
      }
    } = _ref29;
    const parameters = prepareParameters({
      jettonNameFrom,
      jettonNameTo
    }, {
      balance,
      jettonWalletAddress,
      vaultAddress,
      walletV4Address
    });
    if (!parameters) {
      return null;
    }
    const handler = getMessageGenerationHandler({
      jettonNameFrom,
      jettonNameTo
    });
    if (!handler) {
      return null;
    }
    const message = await handler(parameters);
    return message;
  }
  return {
    namespaced: true,
    state: createState,
    getters: {
      isWalletConnected,
      walletAddress,
      isTestWalletAddress,
      isTonConnectUILoading
    },
    mutations: {
      updateTonConnectUILoading,
      updateTonConnectUI,
      setIsWalletConnected,
      setUnsubscribeHandler
    },
    actions: {
      initialize,
      disconnectWallet,
      dispose,
      sendTransaction,
      openSelectWalletModal,
      prepareTransactionMessage
    }
  };
}export{create as createTonConnectStore,jettonsPool as jettonsPoolService,jettons as jettonsService,statistics as statisticsService,wallet as walletService};